/* In your CSS file */
.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    );
    border-radius: inherit;
    width: 100%;
    height: 100%;
    z-index: 2; /* Ensure the overlay is above the image */
  }
  
  