
/* CSS */
.calendly-button  {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif; /* Adding Roboto font */
  font-weight: bold;
  font-size: 12px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
   background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer; /* Add this line */

}

.calendly-button :focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

@media screen and (max-width: 600px) {
  .calendly-button {
    font-size: 10px !important;
    padding: 12px 10px !important;
}
}

@media screen and (max-width: 400px) {
  .calendly-button {
      /* Adjust the size of the button for smaller screens */
      font-size: 10px;  /* Smaller font size */
      padding: 12px 10px;  /* Smaller padding */
      /* Add other styles as needed */
  }
}

@media screen and (max-width: 375px) {
  .calendly-button {
      /* Adjust the size of the button for smaller screens */
      font-size: 10px;  /* Smaller font size */
      padding: 12px 10px;  /* Smaller padding */
      /* Add other styles as needed */
  }
}