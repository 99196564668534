@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');


.h1-as-h5 {
    font-family: 'Roboto Slab', sans-serif !important;
    font-size: 20px !important; /* Adjust as needed */
    line-height: 1.375 !important;
  }
  
