.calendly-button {
    width: 100%; /* Set the width to 100% of the parent container */
    background-color: #000 !important; /* Example color - you can choose any color */
    color: white; /* Text color */
    border: none; /* Remove any default border */
    padding: 8px 20px; /* Add some padding for better appearance */
    border-radius: 5px; /* Optional: to have rounded corners */
    font-size: 14px; /* Adjust font size as needed */
    cursor: pointer; /* Cursor to pointer to indicate it's clickable */
    /* Add any additional styling you desire */
  }

  .calendly-button, .calendly-button a {
    background-color: black !important;
    color: white !important; /* Assuming you want white text */
    /* Other styling properties */
  }
  
  .calendly-button-wrapper .calendly-popup-button {
    background-color: black !important;
    color: white !important;
    /* Other styling properties */
  }
  